import { api } from '../api/fetch';

export const shops = {
    state: {
        shopItems: [],
    },

    actions: {
        loadShops({ commit }, type) {
            api.fetchShops(type)
                .then((response) => {
                    const shopItems = [];

                    for (let i = 0; i < response.data.results.length; i += 1) {
                        shopItems.push(response.data.results[i]);
                    }

                    commit('setShopItems', shopItems);
                })
                .catch(() => {
                    commit('setShopItems', []);
                });
        },
    },

    mutations: {
        setShopItems(state, shopItems) {
            state.shopItems = shopItems;
        },
    },

    getters: {
        getShopItems(state) {
            return state.shopItems;
        },
    },
};
