/* global grecaptcha */
import getParents from '../util/getParents';

let selectedFirst = false;

const validateEmail = (email) => {
    let valid = true;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(email.value)) {
        valid = false;
    }

    return valid;
};

const validateDate = (date) => {
    let valid = true;
    const re = /^(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}$/;

    if (!re.test(date.value)) {
        valid = false;
    }

    return valid;
};

const buildError = (element) => {
    const holder = getParents(element, '.form-group');

    if (!holder || holder.querySelector('.form-group__message--error') !== null) {
        return false;
    }

    holder.classList.remove('form-group--valid');
    holder.classList.add('form-group--invalid');
};

const checkErrorOnInput = (button, form) => {
    const inputs = form.querySelectorAll('[required], [aria-required="true"], [type=email]');

    const buttonVisibility = (valid, input, holder) => {
        if (!valid) {
            buildError(input);
            button.disabled = true;
        } else {
            holder.classList.remove('form-group--invalid');
            holder.classList.add('form-group--valid');

            // check if there are any invalid fields left
            const error = form.querySelectorAll('.form-group--invalid');

            if (error.length !== 0) {
                button.disabled = true;
            } else {
                button.disabled = false;
            }
        }
    };

    Array.from(inputs).forEach((input) => {
        if (input.tagName === 'SELECT') {
            input.nextElementSibling.addEventListener('click', () => {
                if (selectedFirst) {
                    const holder = getParents(input, '.form-group');
                    let valid = true;

                    if (input.value === '') {
                        valid = false;
                    }

                    buttonVisibility(valid, input, holder);
                }
            });
        } else {
            input.addEventListener('input', () => {
                const holder = getParents(input, '.form-group');

                let valid = true;

                if (input.value === '' || (input.type === 'checkbox' && !input.checked)) {
                    valid = false;
                }

                if (input.type === 'email') {
                    valid = validateEmail(input);
                }

                if (input.type === 'text' && input.name.includes('day')) {
                    valid = validateDate(input);
                }
                buttonVisibility(valid, input, holder);
            });
        }
    });
};

const requiredInputs = (form) => {
    const inputs = form.querySelectorAll('[required], [aria-required="true"], [type=email]');
    const errors = [];
    let validForm = true;

    Array.from(inputs).forEach((input) => {
        if (input.value === '' || (input.type === 'checkbox' && !input.checked)) {
            buildError(input);
            selectedFirst = true;
            errors.push('error empty');
        }

        if (input.type === 'email') {
            if (!validateEmail(input)) {
                errors.push('error email');
            }
        }

        if (input.type === 'text' && input.name.includes('day')) {
            if (!validateDate(input)) {
                errors.push('error date');
            }
        }
    });

    if (errors.length !== 0) {
        validForm = false;
    }

    return validForm;
};

const validateRecaptcha = (token) => {
    const recaptchaResponses = document.querySelectorAll('.g-recaptcha-response');

    Array.from(recaptchaResponses).forEach((response) => {
        if (response.value === token) {
            const form = getParents(response, '.form-recaptcha');
            if (form) {
                form.classList.add('form-recaptcha-valid');
            }
        }
    });
};

const validateOnSubmit = () => {
    const form = document.querySelectorAll('form');

    if (form.length === 0) return;

    const siteKeyEl = document.querySelector('#recaptcha_key');
    const widgetContainer = [];
    let siteKey = '';
    if (siteKeyEl) {
        siteKey = siteKeyEl.dataset.key;
        window.validateRecaptcha = validateRecaptcha;
    }

    Array.from(form).forEach((el) => {
        let hasRecaptcha = false;
        const button = el.querySelector('[type="submit"]');

        // Check if form is valid on input
        checkErrorOnInput(button, el);

        if (!button) return;

        if (el.classList.contains('form-recaptcha')) {
            hasRecaptcha = true;

            const interval = setInterval(() => {
                if (window.grecaptcha) {
                    const { grecaptcha } = window;
                    const widgetID = el.querySelector('[id^="recaptchaWidget-"]');

                    grecaptcha.ready(() => {
                        widgetContainer[widgetID.id] = [];
                        widgetContainer[widgetID.id].push(grecaptcha.render((widgetID), {
                            sitekey: siteKey,
                            size: 'invisible',
                            callback: validateRecaptcha,
                        }));
                    });
                }
                clearInterval(interval);
            }, 100);
        }

        // Check if form is valid on click
        button.addEventListener('click', (ev) => {
            let hasRecaptchaValid = false;
            const validateIt = requiredInputs(el);
            const widgetID = el.querySelector('[id^="recaptchaWidget-"]');

            grecaptcha.execute(widgetContainer[widgetID.id][0]);

            if (!validateIt || (el.classList.contains('form-recaptcha') && (widgetContainer[widgetID.id] === undefined || grecaptcha.getResponse(widgetContainer[widgetID.id][0]) === ''))) {
                ev.preventDefault();
            }

            if (el.classList.contains('form-recaptcha-valid')) {
                hasRecaptchaValid = true;
            }

            if (validateIt && (hasRecaptchaValid && hasRecaptcha)) {
                el.submit();
                button.disabled = true;
                grecaptcha.reset(widgetContainer[widgetID.id][0]);
            }
        });
    });
};

export default function validate() {
    validateOnSubmit();
}
