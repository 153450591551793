import lazyLoading from '../components/lazy-load';
import accordion from '../components/accordion';
import customSelect from '../forms/custom-select';
// import { initCookieBar } from '../components/cookies';
import calculateHeight from '../util/height';
import modalFN from '../modals/modal';
import validate from '../forms/validation';
import scrollTo from '../components/scroll-to';
import Navbar from '../navbar/default';
import productInit from '../components/product';
import toggle from '../components/toggle';
import sliderStepsInit from '../components/steps-slider';
import slider from '../slider/slider-product';
import initAnimations from '../components/animations';
import initShops from '../indexes/app-shops';
import fileUpload from '../forms/fileUpload';
import navToggle from '../components/nav-toggle';

export default () => {
    const navbar = new Navbar('.navbar');
    initAnimations();
    lazyLoading();
    accordion();
    customSelect();
    // initCookieBar();
    calculateHeight();
    modalFN();
    validate();
    scrollTo();
    navbar.execute();
    productInit();
    slider();
    toggle();
    sliderStepsInit();
    initShops();
    fileUpload();
    navToggle();

    if (!('touchstart' in document.documentElement)) {
        navbar.destroy();
    }
};
