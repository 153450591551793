import { api } from '../api/fetch';

export const faqs = {
    state: {
        items: [],
    },

    actions: {
        loadFaqs({ commit }) {
            const searchTerms = window.location.search.split('?search=').pop().replace(/(\+|%20)/g, ' ');
            let terms = '';

            if (searchTerms !== 'undefined') {
                terms = searchTerms;
            }

            api.fetchFaqs(terms)
                .then((response) => {
                    const filters = [];
                    const items = [];

                    for (let i = 0; i < response.data.results.length; i += 1) {
                        items.push(response.data.results[i]);
                    }

                    if (response.data.filters) {
                        for (let j = 0; j < response.data.filters.length; j += 1) {
                            filters.push(response.data.filters[j]);
                        }
                    }

                    commit('setItems', items);
                    commit('setFilteredItems', items);
                    commit('setFilters', filters);
                })
                .catch(() => {
                    commit('setItems', []);
                    commit('setFilteredItems', []);
                    commit('setFilters', []);
                });

            commit('setTextSearch', searchTerms);
        },
    },
};
