import { scrollToY } from '../util/scrollY';

export default function scrollTo() {
    const scrollToBtns = document.querySelectorAll('.btn-scroll-to');

    if (scrollToBtns.length) {
        Array.from(scrollToBtns).forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                const url = el.getAttribute('href');

                if (url.indexOf('#') !== -1) {
                    const id = url.replace('#', '');
                    const pagePart = document.getElementById(id);
                    const navbar = document.querySelector('.navbar');
                    let navbarHeight = 0;

                    if (navbar) {
                        navbarHeight = navbar.offsetHeight;
                    }

                    const pagePartOffsetTop = (pagePart.getBoundingClientRect().top + window.scrollY) - navbarHeight - 25;
                    scrollToY(pagePartOffsetTop, 500, 'easeInOutQuint');
                }
            });
        });
    }
}
