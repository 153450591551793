import Vue from 'vue';
import store from './store';
import App from './pages/index-shops.vue';

export default function () {
    const sectionID = '#app-shops';
    const container = document.querySelector(sectionID);

    if (!container) {
        return false;
    }

    const app = new Vue({ // eslint-disable-line no-unused-vars
        el: sectionID,
        store,
        render: (h) => h(App),
    });
}
