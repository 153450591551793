<template>
    <div class="webshops">
        <div class="store-locator">
            <form class="store-locator__search" v-on:submit.prevent @submit="initSearch">
                <div class="store-locator__search-view">
                    <div class="form-group">
                        <input type="search" class="form-control" name="search"
                               :placeholder="translations.searchPhysicalShops" value="" autocomplete="off"
                               v-model="textSearch">
                        <button class="icon-search" aria-label="Search nearest shop" type="submit"></button>
                        <button type="button" class="store-locator__search-view__cancel" aria-label="Cancel search" @click="resetSearch()"></button>
                    </div>
                </div>
            </form>

            <div class="store-locator__stores" v-if="this.getShopItems.length > 0 && loaded && !canceled">
                <table>
                    <tbody>
                    <tr class="store-locator__store" v-for="(value) in this.getShopItems">
                        <td class="store-locator__store-inner">
                            <a :href="'https://www.google.com/maps/place/' + value.lat +  ',' +value.lng"
                               target="_blank"
                               :title="value.name"
                               :aria-label="translations.findOnGoogleMaps + ' '+ value.name">
                                <span class="store-locator__inner">
                                    <i class="icon-marker-o-fill"></i>{{ value.name }}
                                </span>
                            </a>
                        </td>

                        <td class="store-locator__store-inner">
                            <a :href="'https://www.google.com/maps/place/' + value.lat +  ',' +value.lng"
                               target="_blank"
                               :title="value.name"
                               :aria-label="translations.findOnGoogleMaps + ' '+ value.name">
                                <span class="store-locator__inner">
                                    {{ value.city }}
                                </span>
                            </a>
                        </td>

                        <td class="store-locator__store-inner">
                            <a :href="'https://www.google.com/maps/place/' + value.lat +  ',' +value.lng"
                               target="_blank"
                               :title="value.name"
                               :aria-label="translations.findOnGoogleMaps + ' '+ value.name">
                                <span class="store-locator__inner">
                                    {{ value.distance }}
                                </span>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="no-results" v-if="this.getShopItems.length === 0 && loaded && !canceled">
                <h4>{{ translations.noResults }}</h4>
            </div>
        </div>
    </div>
</template>

<script>
    /* global translations */
    import {mapGetters} from 'vuex';
    const webShopList = document.querySelectorAll('.modal .webshops--list');

    export default {
        name: 'LayoutShop',

        props: {
            shopItems: Array,
        },

        data() {
            return {
                loaded: false,
                searched: false,
                textSearch: '',
                translations: translations,
                canceled: false,
            };
        },

        computed: {
            getShopItems() {
                return this.$store.getters.getShopItems;
            },
            objects() {
                return this.getShopItems;
            },
        },

        watch: {
            shopItems() {
                this.loaded = true;
            },
        },

        methods: {
            initSearch() {
                this.canceled = false;
                this.searched = true;
                const value = this.textSearch;
                this.$store.dispatch('loadShops', value);

                if (webShopList.length > 0 ) {
                    Array.from(webShopList).forEach((webshop) => {
                        webshop.classList.add('hide');
                    });
                }
            },

            resetSearch() {
                this.searched = false;
                this.textSearch = '';
                this.canceled = true;

                if (webShopList.length > 0 ) {
                    Array.from(webShopList).forEach((webshop) => {
                        webshop.classList.remove('hide');
                    });
                }
            }
        },
    };
</script>
