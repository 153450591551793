import { hide, show } from '../util/show-hide';

const closeAllExceptCurrentEvent = (el, itemsByClassName, termElement, descriptionElement) => {
    let i = 0;
    let j = 0;
    const elements = document.getElementsByClassName(itemsByClassName);
    const arr = [];

    for (; i < elements.length; i += 1) {
        arr.push(elements[i]);

        if (elements[i] === el.parentElement) {
            arr.splice(i, 1);
        }
    }

    for (; j < arr.length; j += 1) {
        const term = arr[j].querySelector(termElement);
        const description = arr[j].querySelector(descriptionElement);

        term.classList.remove('open');
        hide(description);
    }
};

const accordion = () => {
    const accordionHolder = document.querySelectorAll('.accordion-holder');

    if (!accordionHolder.length > 0) return;

    let animating = false;

    Array.from(document.querySelectorAll('.accordion__term')).forEach((el) => {
        el.onclick = (function () {
            const collapse = this.nextElementSibling;

            if (animating) return false;

            if (collapse.classList.contains('show')) {
                hide(collapse);
            } else {
                show(collapse);
                closeAllExceptCurrentEvent(this, 'accordion-item', '.accordion__term', '.accordion__body');
            }

            this.classList.toggle('open');

            animating = true;

            setTimeout(() => {
                animating = false;
            }, 350);
        });
    });
};

export { accordion as default, closeAllExceptCurrentEvent };
