const removeClass = (els, classes) => {
    for (let i = 0; i < els.length; i += 1) {
        if (Array.isArray(classes)) {
            els[i].classList.remove(...classes);
        } else {
            els[i].classList.remove(classes);
        }
    }
};

const removeClassWithRegex = (els, regex) => {
    const re = new RegExp(`\\S*${regex}\\S*`, 'g');
    for (let i = 0; i < els.length; i += 1) {
        const classes = (els[i].className.match(re) || []).join(' ');
        if (classes) els[i].classList.remove(classes);
    }
};

export { removeClass, removeClassWithRegex };
