import getParents from '../util/getParents';

export default function navToggle() {
    const navItems = document.querySelectorAll('.navbar__nav .nav-item');

    if (navItems.length) {
        navItems.forEach((nav) => {
            const toggle = nav.querySelector('.js-toggle-dropdown');

            if (toggle) {
                toggle.addEventListener('click', () => {
                    nav.classList.toggle('open');
                });
            }
        });
    }
}
