<template>
    <LayoutShops :shopItems="shopItems"></LayoutShops>
</template>

<script>
    import LayoutShops from '../components/layout-shops.vue';

    export default {
        name: 'Shops',

        components: {
            LayoutShops,
        },

        computed: {
            shopItems() {
                return this.$store.getters.getShopItems;
            },
        },
    };
</script>
