const sliderCardHorizontal = () => {
	const sliderInstances = document.querySelectorAll('.slider-product-wrapper');

	if (!sliderInstances.length > 0 || (window.innerWidth >= 768 && document.body.classList.contains('home-page'))) return;

	import('tiny-slider/src/tiny-slider').then((root) => {
		const fruitMinis = document.querySelector('.product-fruit-minis');
		const fruitTreasures = document.querySelector('.product-fruit-treasures');

		let itemsByGroup = 4;
		let gutterByGroup = 30;

		if (fruitMinis || fruitTreasures) {
			itemsByGroup = 3;
			gutterByGroup = 0;
		}

		Array.from(sliderInstances).forEach((sliderInstance) => {
			root.tns({
				container: sliderInstance.querySelector('.slider-product__slider'),
				controlsContainer: document.querySelector('.arrows--slider'),
				arrowKeys: true,
				controls: true,
				loop: false,
				nav: false,
				gutter: gutterByGroup,
				mouseDrag: true,
				items: 1,
				responsive: {
					768: {
						items: itemsByGroup,
						slideBy: 1,
					},
				},
			});
		});
	});
};

export default function slider() {
	sliderCardHorizontal();
}
