export default function modalFn() {
    const modals = document.querySelectorAll('.modal');
    let isVisible = false;

    if (!modals.length > 0) return;

    const html = document.documentElement;
    const { body } = document;
    const backdrop = document.createElement('div');

    backdrop.className = 'modal-backdrop fade';

    const modalClose = () => {
        backdrop.classList.remove('show');
        html.classList.remove('modal-open');

        Array.from(modals).forEach((el) => {
            el.classList.remove('show');
            setTimeout(() => {
                el.removeAttribute('style');
            }, 150);
        });

        setTimeout(() => {
            const backdropElement = document.querySelector('.modal-backdrop');
            html.classList.remove('modal-open');
            body.classList.remove('modal-open');
            backdropElement.parentElement.removeChild(backdropElement);
            isVisible = false;
        }, 150);
    };

    const modalCloseEvent = () => {
        if (isVisible) {
            document.addEventListener('keyup', (event) => {
                if (event.key === 'Escape' || event.key === 'Esc') {
                    modalClose();
                }
            });

            Array.from(modals).forEach((el) => {
                const close = el.querySelector('[data-dismiss="modal"]');
                if (close) {
                    close.addEventListener('click', (ev) => {
                        ev.preventDefault();
                        modalClose();
                    });
                }
            });
        }
    };

    const modalShow = (type) => {
        const modal = document.querySelector(`[data-modal=${type}`);
        const firstTabIndex = modal.querySelector('[tabindex]');
        body.classList.add('modal-open');
        body.appendChild(backdrop);

        modal.style.display = 'block';

        if (firstTabIndex) {
            firstTabIndex.focus();
        }

        setTimeout(() => {
            backdrop.classList.add('show');
            modal.classList.add('show');
            isVisible = true;

            if (type !== 'emergency') {
                modalCloseEvent();
            }
        }, 150);
    };

    const modalClickEvent = () => {
        const modalViewers = document.querySelectorAll('[data-show-modal]');

        Array.from(modalViewers).forEach((button) => {
            button.addEventListener('click', (ev) => {
                ev.preventDefault();
                modalShow(button.dataset.showModal);
            });
        });
    };

    const modalEmergency = () => {
        const emergencyModal = document.querySelector('[data-modal=emergency');
        if (!emergencyModal) return;
        modalShow('emergency');
    };

    modalClickEvent();
    modalEmergency();
}
