import Vue from 'vue';
import Vuex from 'vuex';

import { filters } from './modules/filters';
import { faqs } from './modules/faqs';
import { shops } from './modules/shops';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        faqs,
        filters,
        shops,
    },
});
