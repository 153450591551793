export const filters = {
    state: {
        activeFilter: false,
        faqcategoryFilters: [],
        filteredItems: [],
        filters: [],
        items: [],
        textSearch: '',
    },

    mutations: {
        setActiveFilter(state, data) {
            state.activeFilter = data;
        },
        setfaqCategoriesFilter(state, data) {
            state.faqcategoryFilters = data;
        },
        setFilteredItems(state, items) {
            state.filteredItems = items;
        },
        setFilters(state, filters) { // eslint-disable-line no-shadow
            state.filters = filters;
        },
        setItems(state, items) {
            state.items = items;
        },
        setTextSearch(state, data) {
            state.textSearch = data;
        },
    },

    actions: {
        asyncSetActiveFilter: ({ commit }, payload) => {
            commit('setActiveFilter', payload);
        },
        asyncSetFilteredItems: ({ commit }, payload) => {
            commit('setFilteredItems', payload);
        },
        asyncsetfaqCategoriesFilter: ({ commit }, payload) => {
            commit('setfaqCategoriesFilter', payload);
        },
        asyncSetTextSearch: ({ commit }, payload) => {
            commit('setTextSearch', payload);
        },
    },

    getters: {
        getActiveFilter(state) {
            return state.activeFilter;
        },
        getFilteredItems(state) {
            return state.filteredItems;
        },
        getFilters(state) {
            return state.filters;
        },
        getItems(state) {
            return state.items;
        },
        getFaqCategoryFilter(state) {
            return state.faqcategoryFilters;
        },
        getTextSearch(state) {
            return state.textSearch;
        },
    },
};
