const HTML = document.documentElement;
const buttonDown = document.querySelector('.font-resizer__btn--smaller');
const buttonUp = document.querySelector('.font-resizer__btn--bigger');
const baseFont = 10;
const fontChanger = 1;
const maxChangeSteps = 10;
const fontSizesSetMultiplier = 1.2;
const fontSizeMultiplier = 1;

const setFontSize = (fontSize) => {
    localStorage.setItem('bearsnacks_size', fontSize);

    HTML.style.setProperty('--font-size-html', `${fontSize + baseFont}px`);

    if (fontSize === 0) {
        buttonDown.disabled = true;
        HTML.classList.remove('font-resize');
        HTML.style.setProperty('--font-size-multiplier', fontSizeMultiplier);
    } else {
        buttonDown.disabled = false;
        HTML.classList.add('font-size-set');
        HTML.style.setProperty('--font-size-multiplier', fontSizesSetMultiplier);
    }

    if (fontSize === maxChangeSteps) {
        buttonUp.disabled = true;
    }
};

const changeFontSizeViaButton = () => {
    let fontSize = Number(localStorage.getItem('bearsnacks_size'));

    buttonDown.addEventListener('click', () => {
        buttonUp.disabled = false;
        fontSize -= fontChanger;

        HTML.style.setProperty('--font-size-html', `${fontSize + baseFont}px`);

        if (fontSize === 0) {
            buttonDown.disabled = true;
        }

        localStorage.setItem('bearsnacks_size', fontSize);
    });

    buttonUp.addEventListener('click', () => {
        buttonDown.disabled = false;
        fontSize += fontChanger;

        HTML.style.setProperty('--font-size-html', `${fontSize + baseFont}px`);

        if (fontSize === maxChangeSteps) {
            buttonUp.disabled = true;
        }

        localStorage.setItem('bearsnacks_size', fontSize);
    });
};

const getFontSize = () => {
    const fontSize = Number(localStorage.getItem('bearsnacks_size'));

    if (fontSize === null) {
        setFontSize(false);
    } else {
        setFontSize(fontSize);
    }
};

export default function fontResizer() {
    getFontSize();
    changeFontSizeViaButton();
}
