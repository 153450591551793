// External Dependencies
import { router } from 'js-dom-router';

// Standard imports
import common from './routes/common';

// Standard init
common();

const home = async () => import(/* webpackChunkName: "scripts/routes/home-page" */ './routes/home');
const cardCollectionPage = async () => import(/* webpackChunkName: "scripts/routes/card-collection-page" */ './routes/card-collection-page');
const faq = async () => import(/* webpackChunkName: "scripts/routes/faq" */ './routes/faq');
const product = async () => import(/* webpackChunkName: "scripts/routes/product" */ './routes/product');

// DOM-based init
// .on(<name of body class>, callback)
router
    .on('home-page', async (event) => (await home()).default(event))
    .on('card-collection-page', async (event) => (await cardCollectionPage()).default(event))
    .on('faq-page', async (event) => (await faq()).default(event))
    .on('product-page', async (event) => (await product()).default(event))
    .ready();
