var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "webshops" }, [
    _c("div", { staticClass: "store-locator" }, [
      _c(
        "form",
        {
          staticClass: "store-locator__search",
          on: {
            submit: [
              function($event) {
                $event.preventDefault()
              },
              _vm.initSearch
            ]
          }
        },
        [
          _c("div", { staticClass: "store-locator__search-view" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.textSearch,
                    expression: "textSearch"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "search",
                  name: "search",
                  placeholder: _vm.translations.searchPhysicalShops,
                  value: "",
                  autocomplete: "off"
                },
                domProps: { value: _vm.textSearch },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.textSearch = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "icon-search",
                attrs: { "aria-label": "Search nearest shop", type: "submit" }
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "store-locator__search-view__cancel",
                attrs: { type: "button", "aria-label": "Cancel search" },
                on: {
                  click: function($event) {
                    return _vm.resetSearch()
                  }
                }
              })
            ])
          ])
        ]
      ),
      _vm._v(" "),
      this.getShopItems.length > 0 && _vm.loaded && !_vm.canceled
        ? _c("div", { staticClass: "store-locator__stores" }, [
            _c("table", [
              _c(
                "tbody",
                _vm._l(this.getShopItems, function(value) {
                  return _c("tr", { staticClass: "store-locator__store" }, [
                    _c("td", { staticClass: "store-locator__store-inner" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://www.google.com/maps/place/" +
                              value.lat +
                              "," +
                              value.lng,
                            target: "_blank",
                            title: value.name,
                            "aria-label":
                              _vm.translations.findOnGoogleMaps +
                              " " +
                              value.name
                          }
                        },
                        [
                          _c("span", { staticClass: "store-locator__inner" }, [
                            _c("i", { staticClass: "icon-marker-o-fill" }),
                            _vm._v(
                              _vm._s(value.name) +
                                "\n                            "
                            )
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "store-locator__store-inner" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://www.google.com/maps/place/" +
                              value.lat +
                              "," +
                              value.lng,
                            target: "_blank",
                            title: value.name,
                            "aria-label":
                              _vm.translations.findOnGoogleMaps +
                              " " +
                              value.name
                          }
                        },
                        [
                          _c("span", { staticClass: "store-locator__inner" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(value.city) +
                                "\n                            "
                            )
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "store-locator__store-inner" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://www.google.com/maps/place/" +
                              value.lat +
                              "," +
                              value.lng,
                            target: "_blank",
                            title: value.name,
                            "aria-label":
                              _vm.translations.findOnGoogleMaps +
                              " " +
                              value.name
                          }
                        },
                        [
                          _c("span", { staticClass: "store-locator__inner" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(value.distance) +
                                "\n                            "
                            )
                          ])
                        ]
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      this.getShopItems.length === 0 && _vm.loaded && !_vm.canceled
        ? _c("div", { staticClass: "no-results" }, [
            _c("h4", [_vm._v(_vm._s(_vm.translations.noResults))])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }