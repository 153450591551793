import { removeClass } from '../util/removeClass';

export default function productInit() {
    const HTML = document.documentElement;
    if (window.innerWidth <= 1024 || HTML.classList.contains('contrast-mode')) return;

    const section = document.querySelector('.section--products:not(.section--products--still)');
    if (!section) return;

    const products = section.querySelectorAll('.product:not(.product--still)');
    const activeButton = document.querySelector('.btn--slider.active');
    const colors = [];

    let i = 0;

    const regexClassListValue = (classValue, pattern = /(\b(bg-|text-)\S+\b)/ig) => classValue.match(pattern)[0];

    Array.from(products).forEach((product, idx) => {
        if (product.dataset.background) {
            colors.push({
                index: idx,
                background: `bg-${product.dataset.background}`,
                text: `text-${product.dataset.background}`,
            });
        }
    });

    const changeBackground = () => {
        removeClass(products, 'current');
        products[colors[i].index].classList.add('current');
        section.classList.replace(regexClassListValue(section.classList.value), colors[i].background);
        if (activeButton) activeButton.classList.replace(regexClassListValue(activeButton.classList.value), colors[i].text);

        i += 1;
        if (i === colors.length) {
            i = 0;
        }
    };

    let changeBackgroundInterval = setInterval(changeBackground, 3000);
    const changeBackgroundOnHover = () => {
        Array.from(products).forEach((product) => {
            const productBackground = `bg-${product.dataset.background}`;
            if (productBackground) {
                product.addEventListener('mouseover', () => {
                    clearInterval(changeBackgroundInterval);
                    removeClass(products, 'current');
                    product.classList.add('current');
                    section.classList.replace(regexClassListValue(section.classList.value), productBackground);
                    if (activeButton) activeButton.classList.replace(regexClassListValue(activeButton.classList.value), `text-${product.dataset.background}`);
                });

                product.addEventListener('mouseout', () => {
                    clearInterval(changeBackgroundInterval);
                    changeBackgroundInterval = setInterval(changeBackground, 3000);
                });
            }
        });
    };

    changeBackgroundOnHover();
}
