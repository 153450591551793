import { removeClass } from '../util/removeClass';

const HTML = document.documentElement;

const setContrastMode = (type) => {
    localStorage.setItem('bearsnacks_contrast_mode', type);

    HTML.classList.remove('contrast-mode-avg', 'contrast-mode-high', 'contrast-mode-max');

    if (type !== 'avg' && type !== 'high' && type !== 'max') {
        HTML.classList.remove('contrast-mode');
    }

    if (type === 'avg') {
        HTML.classList.add('contrast-mode', 'contrast-mode-avg');
    } else if (type === 'high') {
        HTML.classList.add('contrast-mode', 'contrast-mode-high');
    } else if (type === 'max') {
        HTML.classList.add('contrast-mode', 'contrast-mode-max');
    }
};

const changeContrastModeViaButton = () => {
    const toggles = document.querySelectorAll('.contrast-mode-set__toggle');

    Array.from(toggles).forEach((el) => {
        el.addEventListener('click', () => {
            let mode = false;

            if (!el.classList.contains('active')) {
                removeClass(toggles, 'active');
                el.classList.add('active');
            } else {
                el.classList.remove('active');
            }

            if (el.classList.contains('active')) {
                mode = el.getAttribute('data-type');

                if (el.classList.contains('contrast-mode-set__toggle--avg')) {
                    localStorage.setItem('bearsnacks_contrast_mode', 'avg');
                } else if (el.classList.contains('contrast-mode-set__toggle--high')) {
                    localStorage.setItem('bearsnacks_contrast_mode', 'high');
                } else if (el.classList.contains('contrast-mode-set__toggle--high')) {
                    localStorage.setItem('bearsnacks_contrast_mode', 'max');
                }
            } else {
                mode = false;

                localStorage.setItem('bearsnacks_contrast_mode', 'false');
            }

            setContrastMode(mode);
        });
    });
};

const getContrastMode = () => {
    const contrastMode = localStorage.getItem('bearsnacks_contrast_mode');

    if (contrastMode === null) {
        setContrastMode(false);
    } else {
        setContrastMode(contrastMode);
    }
};

const switchContrastMode = () => {
    const activeClass = 'active';

    if (HTML.classList.contains('contrast-mode-avg')) {
        document.querySelector('.contrast-mode-set__toggle--avg').classList.add(activeClass);
    } else if (HTML.classList.contains('contrast-mode-high')) {
        document.querySelector('.contrast-mode-set__toggle--high').classList.add(activeClass);
    } else if (HTML.classList.contains('contrast-mode-max')) {
        document.querySelector('.contrast-mode-set__toggle--max').classList.add(activeClass);
    }
};

export default function modeContrast() {
    getContrastMode();
    changeContrastModeViaButton();
    switchContrastMode();
}
