const sliderStepsInit = () => {
    const sliderInstances = document.querySelectorAll('.slider-steps');

    if (!sliderInstances.length > 0) return;

    import('tiny-slider/src/tiny-slider').then((root) => {
        Array.from(sliderInstances).forEach((sliderInstance) => {
            root.tns({
                container: sliderInstance,
                nav: false,
                controls: false,
                arrowKeys: true,
                loop: false,
                speed: 500,
                autoWidth: true,
                mouseDrag: true,
                gutter: 40,
            });
        });
    });
};

export default () => {
    if (window.innerWidth < 768) {
        sliderStepsInit();
    }
};
