export default function fileUpload() {
    const formGroupUpload = document.querySelector('.form-group--upload');
    if (!formGroupUpload) return;

    const fileInput = formGroupUpload.querySelector('input[type="file"]');

    if (!fileInput) return;

    const handeFile = () => {
        const formGroupLabel = formGroupUpload.querySelector('label');
        const { files } = fileInput;

        if (files.length > 0) {
            formGroupLabel.innerHTML = files[0].name;
        }
    };

    fileInput.addEventListener('change', function () {
        handeFile(this.files);
    });
}
