import { keys } from '../util/keys';
import fontResizer from '../components/font-resizer';
import modeContrast from '../components/mode-contrast';

class Navbar {
    constructor(element) {
        // Defaults
        this.defaults = {
            scrollPosition: 0,
            body: document.body,
            main: document.querySelector('main'),
        };

        // Elements
        this.elements = {
            default: document.querySelector(element),
            skip: document.querySelector('.skip-to-content'),
            main: document.getElementById('main'),
            navItems: document.querySelectorAll('.nav-link'),
            toggler: document.querySelector('.navbar__toggler'),
            searchToggler: document.querySelector('.navbar__toggler-search'),
            searchInput: document.querySelector('.navbar__search input'),
            searchClose: document.querySelector('.navbar__search-view__cancel'),
            switcher: document.querySelector('.navbar__language-switcher .language--current'),
            languages: document.querySelectorAll('.switcher__languages .language'),
            settings: document.querySelector('.navbar__settings'),
        };
    }

    scroll() {
        const change = () => {
            if (window.scrollY > 15) {
                this.defaults.body.classList.add('nav-scrolling');
            } else {
                this.defaults.body.classList.remove('nav-scrolling');
            }
        };

        // On init
        change();

        // On scroll
        window.onscroll = () => {
            change();
        };
    }

    toggle(destroy = false) {
        const toggleEvent = () => {
            if (this.defaults.body.classList.contains('nav-collapsed')) {
                this.defaults.main.removeAttribute('style');
                setTimeout(() => {
                    window.scrollTo(0, this.defaults.scrollPosition);
                }, 1);

                this.defaults.body.classList.remove('nav-collapsed');
            } else {
                this.defaults.scrollPosition = window.scrollY;
                this.defaults.main.style.marginTop = `-${this.defaults.scrollPosition}px`;
                this.defaults.body.classList.add('nav-collapsed');
            }

            this.defaults.body.classList.add('nav-collapsing');

            this.elements.toggler.addEventListener('animationend', () => {
               this.defaults.body.classList.remove('nav-collapsing');
            });
        };

        if (!destroy) {
            this.elements.toggler.addEventListener('click', toggleEvent);
        } else {
            this.elements.toggler.removeEventListener('click', toggleEvent);
        }
    }

    languageSwitcher() {
        if (!this.elements.switcher) return false;

        const toggleEvent = () => {
            if (this.defaults.body.classList.contains('language-collapsed')) {
                this.defaults.body.classList.remove('language-collapsed');
            } else {
                this.defaults.body.classList.add('language-collapsed');
            }
        };

        this.elements.switcher.addEventListener('click', toggleEvent);
    }

    search() {
        if (!this.elements.searchToggler) return false;

        const toggleEvent = () => {
            if (this.defaults.body.classList.contains('search-collapsed')) {
                this.defaults.body.classList.remove('search-collapsed');
                this.elements.switcher.focus();
            } else {
                this.defaults.body.classList.add('search-collapsed');
                this.elements.searchInput.focus();
                this.elements.searchInput.setAttribute('tabindex', '0');
                this.elements.searchClose.setAttribute('tabindex', '0');
            }
        };

        this.elements.searchToggler.addEventListener('click', toggleEvent);
        this.elements.searchClose.addEventListener('click', toggleEvent);
    }

    a11y() {
        if (('touchstart' in document.documentElement)) return false;

        let currentIndex;

        const gotoIndex = (idx, elements = this.elements.navItems) => {
            if (idx === elements.length) {
                idx = 0;
            } else if (idx < 0) {
                idx = elements.length - 1;
            }
            elements[idx].focus();
            currentIndex = idx;
        };

        fontResizer();
        modeContrast();

        this.elements.searchInput.setAttribute('tabindex', '-1');
        this.elements.searchClose.setAttribute('tabindex', '-1');

        this.elements.switcher.onfocus = () => {
            this.defaults.body.classList.remove('search-collapsed');
        };

        if (this.elements.settings) {
            const modalAccessibility = document.querySelector('.web-accessibility');

            this.elements.settings.addEventListener('keydown', (e) => {
                if (e.keyCode === keys.enter || e.keyCode === keys.down) {
                    this.elements.settings.click();
                    modalAccessibility.firstElementChild.focus();
                }

                e.preventDefault();
            });

            modalAccessibility.addEventListener('keydown', (e) => {
                if (e.keyCode === keys.tab) {
                    e.target.addEventListener('focusout', (ev) => {
                        if (!document.querySelector('.modal.show').contains(ev.relatedTarget)) {
                            document.querySelector('.modal.show [data-dismiss="modal"]').click();

                            if (this.elements.languages) {
                                this.elements.switcher.focus();
                            } else {
                                this.elements.skip.click();
                            }
                        }
                    });
                }
            });
        }

        if (this.elements.languages) {
            this.elements.switcher.addEventListener('keydown', (e) => {
                if (e.keyCode === keys.enter || e.keyCode === keys.down) {
                    this.elements.switcher.click();
                    setTimeout(() => {
                        this.elements.languages[0].focus();
                    }, 100);

                    Array.from(this.elements.languages).forEach((el, i) => {
                        if (i === 0) {
                            el.setAttribute('tabindex', '0');
                            el.addEventListener('focus', () => {
                                currentIndex = 0;
                            });
                        } else {
                            el.setAttribute('tabindex', '-1');
                        }

                        el.addEventListener('keydown', (ev) => {
                            // eslint-disable-next-line default-case
                            switch (ev.keyCode) {
                                case keys.tab: {
                                    if ((currentIndex + 1) === this.elements.languages.length) {
                                        this.elements.switcher.click();
                                    } else if (e.shiftKey && currentIndex === 0) {
                                        this.elements.searchToggler.focus();
                                    } else if (e.shiftKey) {
                                        gotoIndex(currentIndex - 1, this.elements.languages);
                                    } else {
                                        gotoIndex(currentIndex + 1, this.elements.languages);
                                    }
                                    break;
                                }
                                case keys.enter: {
                                    el.click();
                                    break;
                                }
                                case keys.up: {
                                    gotoIndex(currentIndex - 1, this.elements.languages);
                                    break;
                                }
                                case keys.down: {
                                    gotoIndex(currentIndex + 1, this.elements.languages);
                                    break;
                                }
                                case keys.esc: {
                                    this.elements.switcher.click().focus();
                                    el.setAttribute('tabindex', '-1');
                                    break;
                                }
                            }
                            ev.preventDefault();
                        });
                    });

                    e.preventDefault();
                }
            });
        }

        Array.from(this.elements.navItems).forEach((el, i) => {
            if (i === 0) {
                el.setAttribute('tabindex', '0');
                el.addEventListener('focus', () => {
                    currentIndex = 0;
                });
            } else {
                el.setAttribute('tabindex', '-1');
            }

            el.addEventListener('focus', () => {
                Array.from(this.elements.navItems).forEach((item) => item.setAttribute('aria-expanded', 'false'));
            });

            el.addEventListener('keydown', (e) => {
                // eslint-disable-next-line default-case
                switch (e.keyCode) {
                    case keys.right: {
                        gotoIndex(currentIndex + 1);
                        break;
                    }
                    case keys.left: {
                        gotoIndex(currentIndex - 1);
                        break;
                    }
                    case keys.tab: {
                        if ((currentIndex + 1) === this.elements.navItems.length) {
                            this.elements.searchToggler.focus();
                        } else if (e.shiftKey && currentIndex === 0) {
                            this.elements.skip.focus();
                        } else if (e.shiftKey) {
                            gotoIndex(currentIndex - 1);
                        } else {
                            gotoIndex(currentIndex + 1);
                        }
                        break;
                    }
                    case keys.enter:
                    case keys.down:
                    case keys.up: {
                        el.click();
                        break;
                    }
                    case keys.esc: {
                        this.elements.skip.focus();
                        break;
                    }
                }
                e.preventDefault();
            });
        });
    }

    execute() {
        this.toggle();
        this.languageSwitcher();
        this.scroll();
        this.search();
        this.a11y();
    }

    destroy() {
        this.toggle(true);
    }
}

export default Navbar;
