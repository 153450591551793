const initAnimations = () => {
    const observerElements = document.querySelectorAll('.animate-inview');

    const observerOptions = {
        root: null,
        rootMargin: '-50px',
    };

    const Observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    entry.target.classList.add('animation--active');
                    Observer.unobserve(entry.target);
                }
            });
        },
        observerOptions,
    );

    observerElements.forEach((el) => {
        Observer.observe(el);
    });
};

export default () => {
    initAnimations();
};
