import axios from 'axios';
import { appConfig } from '../indexes/config';
import { removeClass } from '../util/removeClass';

async function getLanguageByWebsite(website) {
    try {
        return axios.get(`${appConfig.API}website/lang/${website}`);
    } catch (err) {
        throw new Error(err);
    }
}

const setLanguagesByWebsite = (id) => {
    const languagesSelector = document.querySelectorAll('[name="language"]');

    Array.from(languagesSelector).forEach((el) => {
        getLanguageByWebsite(id).then((response) => {
            const languages = response.data.locales;
            const languageSelectWrapper = el.parentElement;
            const selected = languageSelectWrapper.querySelector('.select-selected');
            const selectItemsBody = languageSelectWrapper.querySelector('.select-items__body');
            // Empty first
            el.innerHTML = '';
            selectItemsBody.innerHTML = '';
            // Set selected item by first language
            selected.innerHTML = languages[0].title;

            if (languages.length > 1) {
                languageSelectWrapper.classList.remove('disabled');
                // Iterate over languages
                for (let i = 0; i < languages.length; i += 1) {
                    const language = languages[i];
                    const option = document.createElement('option');
                    const div = document.createElement('div');
                    // Set option properties
                    option.text = language.title;
                    option.value = language.locale;
                    // Add option to selector
                    el.options.add(option);
                    // Set div properties
                    div.textContent = language.title;
                    div.classList.add('select-items__body__option', `select-items__body__option--${i}`);

                    if (i === 0) {
                        div.classList.add('same-as-selected');
                    }

                    selectItemsBody.appendChild(div);

                    div.addEventListener('click', () => {
                        if (div.innerText.toLowerCase().replace(/\s/g, '') === el.options[i].innerHTML.toLowerCase().replace(/\s/g, '')) {
                            removeClass(selectItemsBody.querySelectorAll('.select-items__body__option'), 'same-as-selected');
                            el.selectedIndex = i;
                            selected.innerHTML = div.innerHTML;
                            div.classList.add('same-as-selected');
                        }
                    });
                }
            } else {
                languageSelectWrapper.classList.add('disabled');
            }
        }).catch((err) => {
            throw new Error(err);
        });
    });
};

export { setLanguagesByWebsite };
