import getParents from '../util/getParents';

const switchToggle = () => {
    const switchtoggle = document.querySelector('.switch__toggle');

    if (!switchtoggle) return;

    let toggler = document.querySelector('.switch__btn:not(.active)');
    const parent = getParents(toggler, '.switch-toggle');

    const switchParentClass = () => {
        toggler.parentElement.querySelector('.active').classList.remove('active');
        parent.querySelector('.switch__arrow-holder').classList.remove('default');
    };

    const animateSection = (toggleClass) => {
        const sections = document.querySelectorAll('.switch-wrapper');

        if (!sections.length > 0) return;

        Array.from(sections).forEach((el) => {
            el.classList.toggle(toggleClass);
        });
    };

    const init = () => {
        const checkState = getParents(toggler, '.switch-toggle');

        switchParentClass();

        if (checkState.classList.contains('others')) {
            parent.classList.remove('others');
            parent.querySelector('.switch__arrow-holder').classList.add('backwards');
            parent.querySelector('.switch__arrow-holder').classList.remove('forwards');

            setTimeout(() => {
                parent.querySelector('.switch__arrow-holder').classList.add('default');
                parent.querySelector('.switch__arrow-holder').classList.remove('backwards');
            }, 1250);
        } else {
            parent.classList.add('others');
            parent.querySelector('.switch__arrow-holder').classList.add('forwards');
            parent.querySelector('.switch__arrow-holder').classList.remove('backwards');
        }

        animateSection('others');
        reInit(); // eslint-disable-line no-use-before-define
    };

    const reInit = () => {
        toggler.classList.add('active');
        toggler.removeEventListener('click', init);
        toggler = document.querySelector('.switch__btn:not(.active)');
        toggler.addEventListener('click', init);

        toggler.disabled = true;

        setTimeout(() => {
            toggler.disabled = false;
        }, 1250);
    };

    setTimeout(() => {
        parent.querySelector('.switch__arrow-holder').classList.remove('init');
        parent.querySelector('.switch__arrow-holder').classList.add('default');
    }, 1400);
    toggler.addEventListener('click', init);
};

export default function toggle() {
    switchToggle();
}
